import React, { ChangeEvent, useRef, useState, MouseEvent, useEffect } from 'react';
import './App.scss';
import { from, map, take, timer } from 'rxjs';

function App() {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [serverResponse, setServerResponse] = useState<string | null>(null);
  const [loadingText, setLoadingText] = useState('.');

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const subscription = timer(0, 500).subscribe(() => {
      setLoadingText((prev) => prev === '...' ? '.' :`${prev}.`);
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [setLoadingText])

  const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(event.target.files);
    setServerResponse(null);
  };

  const handleFormSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (!selectedFiles || (selectedFiles && !selectedFiles.length)) {
      return;
    }

    const formData = new FormData();
    for (let file of selectedFiles) {
      formData.append('files', file);
    }

    setIsUploading(true);
    setServerResponse(null);

    from(
      fetch('https://das-mensch.de/upload', {
        method: 'POST',
        body: formData,
      })
    ).pipe(
      take(1),
      map((response: Response) => {
        setIsUploading(false);
        if (!response.ok) {
          setServerResponse('Error uploading the files!');
          return;
        }
        setServerResponse('Files uploaded successfully!');
      })
    ).subscribe(() => {});
  }

  const handleFileChooserButtonClicked = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    fileInputRef.current?.click();
  }

  return (
    <>
      <div className="black-container">
        <div className="white-container">
          <div className="title">
            <h1>S</h1>
            <h4>imple</h4>
            <h1>T</h1>
            <h4>errific</h4>
            <h1>F</h1>
            <h4>ile</h4>
            <h1>U</h1>
            <h4>pload</h4>
          </div>
          <div className="sub-title">v1.0.0</div>
        <div className="red-container">
          <div className="message-container">
            { selectedFiles && <span className="message client-message">{selectedFiles.length} file{selectedFiles.length > 1 && 's' } selected</span> }
            { isUploading && <span className="message server-message">{loadingText}</span> }
            { serverResponse && <span className="message server-message">{serverResponse}</span> }
          </div>
          <form onSubmit={handleFormSubmit}>
            <button className="p-button" type="button" onClick={handleFileChooserButtonClicked}>Click to add files</button>
            <input ref={fileInputRef} className="file-input" multiple type="file" accept="*" onChange={handlePhotoChange} />
            { selectedFiles && <button className="p-button" type="submit">Upload</button> }
          </form>
        </div>
        </div>
      </div>
    </>
  );
}

export default App;
